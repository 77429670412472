import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import isEmpty from 'lodash/isEmpty'
import config from 'config'
import { isValidLocale } from 'shared/tools/locale-helpers'
import { SerialProps } from 'client/shared/types/serial'
import { parseDomain } from 'parse-domain'
import { TODO_ANY } from '../types/helper'
import { AuthorRole, AuthorWorkTypes } from 'client/shared/types/author'
import { A1 } from 'client/bookmate/pages/a1-page'
import { GHANA } from 'client/shared/reducers/current-user-reducer'

const protocol = 'https://'
const canvas = CANVAS_HOST
export const ALLOWED_STORED_QUERY = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'pid',
  'af_channel',
  'af_ad',
  'c',
  'click_id',
  'xba',
  'campaign',
]

type QueryValue = string | number | boolean | string[] | number[] | boolean[]

export type NullableQueryParams = {
  [key: string]: QueryValue | null | undefined
}

export type QueryParams = {
  [key: string]: QueryValue
}

function getSupportLocale(locale): string {
  return ['ru', 'da'].includes(locale) ? locale : 'en-us'
}

export default {
  root(query?: QueryParams): string {
    return addParamsToPath('/', query || {})
  },

  absoluteRoot(
    domain: string,
    useProtocol = true,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      useProtocol ? `${protocol}${domain}` : domain,
      query || {},
    )
  },

  absoluteRootWithSubdomain(
    locale: string,
    domain: string,
    useProtocol = true,
    query?: QueryParams,
  ): string {
    if (locale === 'ru') locale = 'rus'
    if (locale === config.fallbackLocale) {
      return this.absoluteRoot(domain, useProtocol, query)
    } else {
      return addParamsToPath(
        useProtocol ? `${protocol}${locale}.${domain}` : `${locale}.${domain}`,
        query || {},
      )
    }
  },

  login(query?: QueryParams): string {
    return addParamsToPath('/login', query || {})
  },

  user(login: string, query?: QueryParams): string {
    return addParamsToPath(`/@${login}`, query || {})
  },

  userBooks(login: string, filter: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/books/${filter}`, query || {})
  },

  userBooksAll(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/books/all`, query || {})
  },

  userBooksRecent(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/books/recent`, query || {})
  },

  userBooksReadLater(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/books/not_finished`,
      query || {},
    )
  },

  userBooksFinished(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/books/finished`, query || {})
  },

  userBooksUploads(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/books/uploads`, query || {})
  },

  userAudioBooks(login: string, filter: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/audiobooks/${filter}`,
      query || {},
    )
  },

  userAudioBooksAll(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/audiobooks/all`, query || {})
  },

  userAudioBooksRecent(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/audiobooks/recent`, query || {})
  },

  userAudioBooksNotFinished(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/audiobooks/not_finished`,
      query || {},
    )
  },

  userAudioBooksFinished(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/audiobooks/finished`,
      query || {},
    )
  },

  userComicbooks(login: string, filter: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/comics/${filter}`, query || {})
  },

  userShelves(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/bookshelves/created`,
      query || {},
    )
  },

  userShelvesFollowing(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/bookshelves/following`,
      query || {},
    )
  },

  userSeries(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/series`, query || {})
  },

  userImpressions(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/impressions`, query || {})
  },

  userQuotes(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/quotes`, query || {})
  },

  userBookQuotes(
    login: string,
    bookUuid: string,
    isSerial: boolean,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.user(login)}/books/${bookUuid}/quotes`,
      { ...query, is_serial: isSerial ? true : '' } || {},
    )
  },

  userFollowers(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/connections/followers`,
      query || {},
    )
  },

  userFollowing(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/connections/following`,
      query || {},
    )
  },

  userAchievements(login: string, year: number, query?: QueryParams): string {
    return addParamsToPath(`/@${login}/achievements/${year}`, query || {})
  },

  userFinishedBooksForYear(
    login: string,
    year: number,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.userAchievements(login, year)}/finished-books`,
      query || {},
    )
  },

  userFriendsAchievements(
    login: string,
    year: number,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.userAchievements(login, year)}/following`,
      query || {},
    )
  },

  userPledgeSharingPage(
    login: string,
    year: number,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.userAchievements(login, year)}/pledge-card`,
      query || {},
    )
  },

  settings(login: string, hash = '', query?: QueryParams): string {
    return addParamsToPath(
      `${this.user(login)}/settings${hash && `#${hash}`}`,
      query || {},
    )
  },

  settingsDevices(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.settings(login)}/devices`, query || {})
  },

  settingsMails(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.settings(login)}/email-notifications`,
      query || {},
    )
  },

  settingsPrivacy(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.settings(login)}/privacy`, query || {})
  },

  familySubscription(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.settings(login)}/family-subscription`,
      query || {},
    )
  },

  settingsCookies(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.settings(login)}/cookies`, query || {})
  },

  familySubscriptionInviteSent(
    login: string,
    inviteId: string,
    noLogin = false,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.settings(
        login,
      )}/family-subscription/invite-sent?invite=${inviteId}`,
      { ...query, mode: noLogin ? 'nologin' : '' } || {},
    )
  },

  profile(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.user(login)}/profile`, query || {})
  },

  changePassword(login: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.settings(login)}/change-password`,
      query || {},
    )
  },

  resetPassword(query?: QueryParams): string {
    return addParamsToPath(`/reset-password`, query || {})
  },

  changeEmail(login: string, query?: QueryParams): string {
    return addParamsToPath(`${this.settings(login)}/change-email`, query || {})
  },

  changeCard(
    login: string,
    recurrentUuid: string,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.user(login)}/change-card/${recurrentUuid}`,
      query || {},
    )
  },

  deleteAccount({
    login,
    query,
  }: {
    login: string
    query?: QueryParams
  }): string {
    return addParamsToPath(
      `${this.settings(login)}/delete-account/password`,
      query || {},
    )
  },

  library(query?: QueryParams): string {
    return addParamsToPath(`/library`, query || {})
  },

  bookshelves(query?: QueryParams): string {
    return addParamsToPath(`/bookshelves/all`, query || {})
  },

  bookshelvesTopic(topicName: string, query?: QueryParams): string {
    return addParamsToPath(`/bookshelves/${topicName}`, query || {})
  },

  showcaseBooks(query?: QueryParams): string {
    return addParamsToPath('/books', query || {})
  },

  showcaseAudiobooks(query?: QueryParams): string {
    return addParamsToPath('/audiobooks', query || {})
  },
  kids(query?: QueryParams): string {
    return addParamsToPath(`/decije-knjige`, query || {})
  },
  showcaseComicbooks(query?: QueryParams): string {
    return addParamsToPath('/comicbooks', query || {})
  },

  showcaseByResource(type: string | undefined, query?: QueryParams): string {
    switch (type) {
      case 'books':
        return addParamsToPath('/books', query || {})
      case 'audiobooks':
        return addParamsToPath('/audiobooks', query || {})
      case 'comicbooks':
        return addParamsToPath('/comicbooks', query || {})
      default:
        return addParamsToPath('/books', query || {})
    }
  },

  showcaseSection(
    path: string,
    sectionSlug: string,
    query?: QueryParams,
  ): string {
    return addParamsToPath(`${path}/s-${sectionSlug}`, query || {})
  },

  // path for completing stripe 3D secure payments
  stripePaymentComplete(params?: NullableQueryParams): string {
    return addParamsToPath(`${this.subscription()}/complete`, params || {})
  },

  manageSubscriptions(query?: QueryParams): string {
    return addParamsToPath('/manage-subscriptions', query || {})
  },

  subscription(path?: string, query?: QueryParams): string {
    return addParamsToPath(`/subscription`, { ...query, path } || {})
  },

  subscriptionFeaturedProduct(params?: NullableQueryParams): string {
    return addParamsToPath(
      '/subscription/featured-product',
      { ...params } || {},
    )
  },

  subscriptionSuccess(params?: NullableQueryParams): string {
    return addParamsToPath(`${this.subscription()}/success`, params || {})
  },

  subscriptionThreeMesSuccess(params?: NullableQueryParams): string {
    return addParamsToPath(`/3mesesx99/success`, params || {})
  },

  subscriptionKind(
    productId: string,
    path?: string,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.subscription()}/${productId}`,
      { ...query, path } || {},
    )
  },

  subscriptionUpgrade(
    productId: string,
    path: string,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.subscriptionKind(productId)}/upgrade`,
      { ...query, path } || {},
    )
  },

  specialOffer(
    data: { campaign: string; planName?: string },
    query?: QueryParams,
  ): string {
    if (isEmpty(data)) return ''

    const { campaign, planName } = data
    return addParamsToPath(
      `/special-offers/${campaign}`,
      { ...query, plan: planName } || {},
    )
  },
  lightFriday(
    data: {
      planName?: string
      signInToken?: string
      click_id?: string
    },
    query?: QueryParams,
  ): string {
    return addParamsToPath(`/light-friday/payment`, { ...query, ...data } || {})
  },
  specialOfferPayment(
    data: {
      campaign: string
      planName?: string
      signInToken?: string
      click_id?: string
      plan?: string
      variant?: string
    },
    query?: QueryParams,
  ): string {
    if (isEmpty(data)) return ''
    return addParamsToPath(
      `${this.specialOffer({ campaign: data.campaign })}/payment`,
      { ...query, ...data } || {},
    )
  },

  specialOfferSuccess({
    campaign,
    query,
  }: {
    campaign: string
    query?: QueryParams
  }): string {
    return addParamsToPath(
      `${this.specialOffer({ campaign })}/success`,
      query || {},
    )
  },
  authorsFeed(query?: QueryParams): string {
    return addParamsToPath(`/feed/authors`, query || {})
  },
  friendsFeed(query?: QueryParams): string {
    return addParamsToPath(`/feed/friends`, query || {})
  },

  communityFeed(query?: QueryParams): string {
    return addParamsToPath('/feed/explore', query || {})
  },

  search(query?: QueryParams): string {
    return addParamsToPath('/search', query || {})
  },

  searchAll(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/all/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchBooks(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/books/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchAudioBooks(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/audiobooks/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchComicbooks(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/comicbooks/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchSeries(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/series/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchAuthors(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/authors/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchBookshelves(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/bookshelves/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  searchUsers(searchQuery: string, query?: QueryParams): string {
    return addParamsToPath(
      `/search/users/${encodeURIComponent(searchQuery)}`,
      query || {},
    )
  },

  book(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`/books/${bookUuid}`, query || {})
  },

  serial(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`/serials/${bookUuid}`, query || {})
  },

  bookWithDomain(
    bookUuid: string,
    locale?: string,
    domain = '',
    query?: QueryParams,
  ): string {
    if (locale) {
      return `${this.absoluteRootWithSubdomain(locale, domain)}${this.book(
        bookUuid,
        query,
      )}`
    } else {
      return `${this.absoluteRoot(domain)}${this.book(bookUuid, query)}`
    }
  },

  serialWithDomain(
    bookUuid: string,
    locale?: string,
    domain = '',
    query?: QueryParams,
  ): string {
    if (locale) {
      return `${this.absoluteRootWithSubdomain(locale, domain)}${this.serial(
        bookUuid,
        query,
      )}`
    } else {
      return `${this.absoluteRoot(domain)}${this.serial(bookUuid, query)}`
    }
  },

  comicbook(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`/comicbooks/${uuid}`, query || {})
  },

  comicbookWithDomain(
    uuid: string,
    locale?: string,
    domain = '',
    query?: QueryParams,
  ): string {
    if (locale) {
      return `${this.absoluteRootWithSubdomain(locale, domain)}${this.comicbook(
        uuid,
        query,
      )}`
    } else {
      return `${this.absoluteRoot(domain)}${this.comicbook(uuid, query)}`
    }
  },

  comicbookImpressions(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.comicbook(uuid)}/impressions`, query || {})
  },

  comicbookImpressionForm(uuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.comicbookImpressions(uuid)}/form`,
      query || {},
    )
  },

  comicbookReaders(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.comicbook(uuid)}/readers`, query || {})
  },

  comicbookRelated(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.comicbook(uuid)}/related`, query || {})
  },

  comicbookShelves(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.comicbook(uuid)}/shelves`, query || {})
  },

  comicbookAddToShelf(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.comicbook(uuid)}/add_to_shelf`, query || {})
  },

  audioBook(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`/audiobooks/${uuid}`, query || {})
  },

  audioBookWithDomain(
    uuid: string,
    locale?: string,
    domain = '',
    query?: QueryParams,
  ): string {
    if (locale) {
      return `${this.absoluteRootWithSubdomain(locale, domain)}${this.audioBook(
        uuid,
        query,
      )}`
    } else {
      return `${this.absoluteRoot(domain)}${this.audioBook(uuid, query)}`
    }
  },

  audioBookImpressions(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.audioBook(uuid)}/impressions`, query || {})
  },

  audioBookImpressionForm(uuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.audioBookImpressions(uuid)}/form`,
      query || {},
    )
  },

  audioBookListeners(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.audioBook(uuid)}/listeners`, query || {})
  },

  audioBookAddToShelf(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.audioBook(uuid)}/add_to_shelf`, query || {})
  },

  audioBookRelated(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.audioBook(uuid)}/related`, query || {})
  },

  bookReaders(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/readers`, query || {})
  },

  bookShelves(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/shelves`, query || {})
  },

  bookQuotes(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/quotes`, query || {})
  },

  bookQuote({
    quoteUuid,
    anchor = '',
    query,
  }: {
    quoteUuid: string
    anchor?: string
    query?: QueryParams
  }): string {
    return addParamsToPath(`/quotes/${quoteUuid}${anchor}`, query || {})
  },

  quoteAddToShelf(quoteId: string, query?: QueryParams): string {
    return addParamsToPath(`/quotes/${quoteId}/add_to_shelf`, query || {})
  },

  bookImpressions(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/impressions`, query || {})
  },

  bookImpressionForm(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.bookImpressions(bookUuid)}/form`,
      query || {},
    )
  },

  bookAddToShelf(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/add_to_shelf`, query || {})
  },

  impression({
    impressionUuid,
    anchor = '',
    query,
  }: {
    impressionUuid: string
    anchor?: string
    query?: QueryParams
  }): string {
    return addParamsToPath(
      `/impressions/${impressionUuid}${anchor}`,
      query || {},
    )
  },

  impressionAddToShelf(impressionId: string, query?: QueryParams): string {
    return addParamsToPath(
      `/impressions/${impressionId}/add_to_shelf`,
      query || {},
    )
  },

  bookRelated(bookUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.book(bookUuid)}/related`, query || {})
  },

  serialRelated(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.serial(serialUuid)}/related`, query || {})
  },

  serialReaders(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.serial(serialUuid)}/readers`, query || {})
  },

  serialShelves(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.serial(serialUuid)}/shelves`, query || {})
  },

  serialQuotes(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.serial(serialUuid)}/quotes`, query || {})
  },

  serialImpressions(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.serial(serialUuid)}/impressions`,
      query || {},
    )
  },

  serialImpressionForm(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.serialImpressions(serialUuid)}/form`,
      query || {},
    )
  },

  serialEpisodes(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.serial(serialUuid)}/episodes`, query || {})
  },

  serialAddToShelf(serialUuid: string, query?: QueryParams): string {
    return addParamsToPath(
      `${this.serial(serialUuid)}/add_to_shelf`,
      query || {},
    )
  },

  resource(
    uuid: string,
    type: string,
    query?: QueryParams,
  ): string | undefined {
    switch (type) {
      case 'book':
        return this.book(uuid, query)
      case 'audiobook':
        return this.audioBook(uuid, query)
      case 'comicbook':
        return this.comicbook(uuid, query)
      case 'serial':
        return this.serial(uuid, query)
      case 'series':
        return this.series(uuid, query)
      default:
        break
    }
  },

  resourceRelated(
    uuid: string,
    type: string,
    query?: QueryParams,
  ): string | undefined {
    switch (type) {
      case 'book':
        return this.bookRelated(uuid, query)
      case 'audiobook':
        return this.audioBookRelated(uuid, query)
      case 'comicbook':
        return this.comicbookRelated(uuid, query)
      default:
        break
    }
  },

  codePage(type = '', query?: QueryParams): string {
    return addParamsToPath(`/code/${type}`, query || {})
  },

  a1Page(query?: QueryParams): string {
    return addParamsToPath(`/a1`, { ...query, promo: A1 })
  },

  codigoPage(query?: QueryParams): string {
    return addParamsToPath(`/codigo`, query || {})
  },

  telcelPage(query?: QueryParams): string {
    return addParamsToPath(`/telcel`, query || {})
  },
  threeMesPage(query?: QueryParams): string {
    return addParamsToPath(`/3mesesx99 `, query || {})
  },
  mtnPage(query?: QueryParams): string {
    return addParamsToPath(`/mtn`, { ...query })
  },

  mtnIdentity(query?: QueryParams): string {
    return addParamsToPath(`/api/v5/mtn_ghana/identity_sign_in`, { ...query })
  },

  march8Page(query?: QueryParams): string {
    return addParamsToPath(`/prolece2024`, { ...query })
  },

  sitemapPage(
    resourceType = 'books',
    page?: number,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      removeTrailingSlash(['/sitemap', resourceType, page].join('/')),
      query || {},
    )
  },

  shelf(shelfUuid: string, query?: QueryParams): string {
    return addParamsToPath(`/bookshelves/${shelfUuid}`, query || {})
  },

  shelfWithDomain(
    uuid: string,
    locale?: string,
    domain = '',
    query?: QueryParams,
  ): string {
    if (locale) {
      return `${this.absoluteRootWithSubdomain(locale, domain)}${this.shelf(
        uuid,
        query,
      )}`
    } else {
      return `${this.absoluteRoot(domain)}${this.shelf(uuid, query)}`
    }
  },

  shelfNew(query?: QueryParams): string {
    return addParamsToPath(`/bookshelves/new`, query || {})
  },

  shelfEdit(shelfUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/edit`, query || {})
  },

  shelfAddResource(
    {
      shelfId,
      resourceType,
      resourceId,
    }: {
      shelfId: string
      resourceType: string
      resourceId: string
    },
    params?: NullableQueryParams,
  ): string {
    switch (resourceType) {
      case 'books':
        return this.shelfAddBook(shelfId, resourceId, params)
      case 'serials':
        return this.shelfAddSerial(shelfId, resourceId, params)
      case 'audiobooks':
        return this.shelfAddAudiobook(shelfId, resourceId, params)
      case 'comicbooks':
        return this.shelfAddComicbook(shelfId, resourceId, params)
      case 'quotes':
        return this.shelfAddQuote(shelfId, resourceId, params)
      case 'impressions':
        return this.shelfAddImpression(shelfId, resourceId, params)
      default:
        return ''
    }
  },

  salinasShowcase(query?: QueryParams): string {
    return addParamsToPath('/bibliotecasocio', query || {})
  },

  salinasSignUp(query?: QueryParams): string {
    return addParamsToPath('bibliotecasocio/sign-up', query || {})
  },

  salinasValidate(kind: 'basic' | 'error', query?: QueryParams): string {
    return addParamsToPath(`confirm/${kind}`, query || {})
  },

  searchPickedBooks(path: string, params: NullableQueryParams): string {
    return addParamsToPath(`${path}/books`, params || {})
  },

  searchPickedAudiobooks(path: string, params: NullableQueryParams): string {
    return addParamsToPath(`${path}/audiobooks`, params || {})
  },

  searchPickedComicbooks(path: string, params: NullableQueryParams): string {
    return addParamsToPath(`${path}/comicbooks`, params || {})
  },

  addBookOnShelf(shelfUuid: string, params?: NullableQueryParams): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/add`, params || {})
  },

  shelfChooseAllToAdd(shelfUuid: string, params?: NullableQueryParams): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/add`, params || {})
  },

  shelfAddBook(
    shelfUuid: string,
    bookUuid: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/books/${bookUuid}`,
      params || {},
    )
  },

  shelfAddSerial(
    shelfUuid: string,
    serialUuid: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/serials/${serialUuid}`,
      params || {},
    )
  },

  shelfAddAudiobook(
    shelfUuid: string,
    audiobookUuid: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/audiobooks/${audiobookUuid}`,
      params || {},
    )
  },

  shelfAddComicbook(
    shelfUuid: string,
    comicbookUuid: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/comicbooks/${comicbookUuid}`,
      params || {},
    )
  },

  shelfAddQuote(
    shelfUuid: string,
    quoteId: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/quotes/${quoteId}`,
      params || {},
    )
  },

  shelfAddImpression(
    shelfUuid: string,
    impressionId: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/add/impressions/${impressionId}`,
      params || {},
    )
  },

  shelfBooks(shelfUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/books`, query || {})
  },

  shelfChooseBooksToAdd(
    shelfUuid: string,
    params?: NullableQueryParams,
  ): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/add/books`, params || {})
  },

  shelfPost(
    shelfUuid: string,
    postUuid: string,
    anchor = '',
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelf(shelfUuid)}/posts/${postUuid}${anchor}`,
      query || {},
    )
  },

  shelfPostEdit(
    shelfUuid: string,
    postUuid: string,
    query?: QueryParams,
  ): string {
    return addParamsToPath(
      `${this.shelfPost(shelfUuid, postUuid)}/edit`,
      query || {},
    )
  },

  shelfFollowers(shelfUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.shelf(shelfUuid)}/followers`, query || {})
  },

  series(seriesUuid: string, query?: QueryParams): string {
    return addParamsToPath(`/series/${seriesUuid}`, query || {})
  },

  seriesFollowers(seriesUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.series(seriesUuid)}/followers`, query || {})
  },

  seriesEpisodes(seriesUuid: string, query?: QueryParams): string {
    return addParamsToPath(`${this.series(seriesUuid)}/episodes`, query || {})
  },

  topicPage(topic: string, query?: QueryParams): string {
    return addParamsToPath(`/topics/${topic}`, query || {})
  },

  topicHierarchy({
    topic,
    resourceType,
    subtopic,
    microtopic,
    section,
    query,
  }: {
    topic: string
    resourceType: string | undefined
    subtopic?: string | null | undefined
    microtopic?: string | null | undefined
    section?: string | null | undefined
    query?: QueryParams
  }): {
    topic: string
    all: string
    subtopic: string
    microtopic: string
    section: string
  } {
    const hierarchy = {
      topic: addParamsToPath(`/${resourceType}/t-${topic}`, query || {}),
      all: addParamsToPath(`/${resourceType}/t-${topic}/all`, query || {}),
      subtopic: '',
      microtopic: '',
      section: '',
    }

    if (subtopic) {
      hierarchy.subtopic = addParamsToPath(
        `/${resourceType}/t-${topic}/t-${subtopic}`,
        query || {},
      )
    }

    if (microtopic) {
      hierarchy.microtopic = addParamsToPath(
        `/${resourceType}/t-${subtopic}/t-${microtopic}`,
        query || {},
      )
    }

    if (section) {
      hierarchy.section = addParamsToPath(
        `/${resourceType}/t-${topic}/s-${section}`,
        query || {},
      )
    }

    return hierarchy
  },

  freeTopicPage(locale: string, query?: QueryParams): string {
    const topicNames = {
      ru: 'Бесплатно',
      en: 'Read%20for%20free',
      kk: 'Тегін',
      nl: 'Gratis%20boeken',
      es: 'Libros%20gratis',
      id: 'Buku%20gratis',
    }

    const topic = ['ru', 'nl', 'es', 'id'].includes(locale)
      ? topicNames[locale]
      : topicNames.en

    return addParamsToPath(`/topics/${topic}`, query || {})
  },

  author(authorId: string, query?: QueryParams): string {
    return addParamsToPath(`/authors/${authorId}`, query || {})
  },

  authorWithDomain({
    authorId,
    query,
    locale,
    domain,
  }: {
    authorId: string
    query?: QueryParams
    locale?: string
    domain?: string
  }): string {
    return `${this.absoluteRootWithSubdomain(locale, domain)}${this.author(
      authorId,
      query,
    )}`
  },

  authorImpressions(authorId: string, query?: QueryParams): string {
    return addParamsToPath(
      `/authors/${authorId}/author/impressions`,
      query || {},
    )
  },

  authorQuotes(authorId: string, query?: QueryParams): string {
    return addParamsToPath(`/authors/${authorId}/author/quotes`, query || {})
  },

  authorWorks({
    authorId,
    role,
    worksType,
    query,
  }: {
    authorId: string
    role: AuthorRole
    worksType: AuthorWorkTypes
    query?: QueryParams
  }): string {
    return addParamsToPath(
      `/authors/${authorId}/${role}/${worksType}`,
      query || {},
    )
  },

  authorMediaLinks(authorId: string, query?: QueryParams): string {
    return addParamsToPath(
      `/authors/${authorId}/author/media-links`,
      query || {},
    )
  },

  authorLanguageVariants(authorId: string, query?: QueryParams): string {
    return addParamsToPath(`${this.author(authorId)}/languages`, query || {})
  },

  webReader(path: string, domain: string, query?: QueryParams): string {
    const readerHost = domain.replace(/^www(\d+)\./, 'reader$1.')
    let readerPath = ''

    if (readerHost !== domain) {
      readerPath = addParamsToPath(
        `${protocol}${readerHost}/${path}`,
        query || {},
      )
    } else {
      readerPath = addParamsToPath(
        `${protocol}reader.bookmate.com/${path}`,
        query || {},
      )
    }

    return readerPath
  },

  reader(
    bookUuid: string,
    options: { resourceType: string; seriesUuid?: string },
    query?: QueryParams,
  ): string {
    let path = ''

    path = addParamsToPath(
      `reader/${bookUuid}`,
      {
        ...query,
        from_series: options.seriesUuid,
        resource: options.resourceType,
      } || {},
    )
    return `/${path}`
  },

  getSerialReaderQuery(serial: SerialProps): Record<string, string | number> {
    const episode = serial?.library_card?.current_episode_position || 1
    const episode_uuid = serial?.library_card?.current_episode_uuid || ''

    return { episode_uuid, episode }
  },

  publisher(domain: string): string {
    return `https://publisher.${domain}`
  },

  about(query?: QueryParams): string {
    return addParamsToPath(`/about`, query || {})
  },

  contacts(query?: QueryParams): string {
    return addParamsToPath(`/about/contacts`, query || {})
  },

  jobs(query?: QueryParams): string {
    return addParamsToPath(`/about/jobs`, query || {})
  },

  legal(anchor = '', query?: QueryParams): string {
    return addParamsToPath(`/about/legal${anchor}`, query || {})
  },

  press(query?: QueryParams): string {
    return addParamsToPath(`/about/press`, query || {})
  },

  support(): string {
    return `https://intercom.help/bookmate-534438c41f4d/en/`
  },

  supportSubscriptionLinks(
    locale: string,
  ): { appstore: string; googleplay: string; settings: string } {
    const appstoreLocales = {
      default: 'en-us',
      ru: 'ru-ru',
      da: 'da-dk',
    }
    const articleIdsByLocale = {
      default: {
        googleplay: '203232607',
        settings: '202136933',
      },
    }

    // eslint-disable-next-line no-param-reassign
    locale = getSupportLocale(locale)
    const articleIds = articleIdsByLocale.default

    return {
      appstore: `https://support.apple.com/${
        appstoreLocales[locale] || appstoreLocales.default
      }/HT202039`,
      googleplay: `https://support.bookmate.com/hc/${locale}/articles/${articleIds.googleplay}`,
      settings: `https://support.bookmate.com/hc/${locale}/articles/${articleIds.settings}`,
    }
  },

  gifts(query?: QueryParams): string {
    return addParamsToPath(`/gifts`, query || {})
  },

  giftsActivationSuccess(query?: QueryParams): string {
    return addParamsToPath(`/gifts/success`, query || {})
  },

  giftsSuggestBook(query?: QueryParams): string {
    return addParamsToPath(`/gifts/suggest-book`, query || {})
  },

  giftsCustomisation(query?: QueryParams): string {
    return addParamsToPath(`/gifts/customise`, query || {})
  },

  giftsPayment(query?: QueryParams): string {
    return addParamsToPath(`/gifts/payment`, query || {})
  },

  giftsPaymentSuccess(id?: string, query?: QueryParams): string {
    return addParamsToPath(`/gifts/payment/success`, { ...query, id } || {})
  },

  giftsPaymentFail(query?: QueryParams): string {
    return addParamsToPath(`/gifts/payment/fail`, query || {})
  },

  giftСard(uuid: string, query?: QueryParams): string {
    return addParamsToPath(`/gifts/${uuid}`, query || {})
  },

  browserError(): string {
    return '/errors/browser'
  },

  statusError(status: number): string {
    return `/errors/${status}`
  },

  bookmateRun(query?: QueryParams): string {
    return addParamsToPath(`/bookmate-run`, query || {})
  },

  upload(mode?: string, query?: QueryParams): string {
    if (mode === 'link') {
      return addParamsToPath('/upload/from-external-link', query || {})
    } else {
      return addParamsToPath('/upload', query || {})
    }
  },

  getTheApp(query?: QueryParams): string {
    return addParamsToPath(`/gettheapp`, query || {})
  },

  notifications(query?: QueryParams): string {
    return addParamsToPath('/notifications', query || {})
  },

  reportProblem(resource: string, query?: QueryParams): string {
    return addParamsToPath(`/report-problem`, { ...query, resource } || {})
  },

  canvasQuote({ quoteId, mode }: { quoteId: string; mode: string }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/quotes/${quoteId}?mode=${mode}&${Date.now()}`
  },

  canvasBook({ bookId, mode }: { bookId: string; mode: string }): string {
    // the mode parameter is the name of the social network for sharing, date for prevent cashing
    return `${canvas}/books/${bookId}?mode=${mode}&${Date.now()}`
  },

  canvasAudiobook({
    audiobookId,
    mode,
  }: {
    audiobookId: string
    mode: string
  }): string {
    // the mode parameter can be 'mail' or the name of the social network for sharing
    return `${canvas}/audiobooks/${audiobookId}?mode=${mode}&${Date.now()}`
  },

  canvasAuthor({ authorId, mode }: { authorId: string; mode: string }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/authors/${authorId}?mode=${mode}&${Date.now()}`
  },

  canvasShelf({
    shelfId,
    mode,
    timestamp,
  }: {
    shelfId: string
    mode: string
    timestamp: number
  }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/shelves/${shelfId}?mode=${mode}&${timestamp}&${Date.now()}`
  },

  canvasComicbook({
    comicbookId,
    mode,
  }: {
    comicbookId: string
    mode: string
  }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/comicbooks/${comicbookId}?mode=${mode}&${Date.now()}`
  },

  canvasPost({
    shelfId,
    postId,
    mode,
  }: {
    shelfId: string
    postId: string
    mode: string
  }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/shelves/${shelfId}/posts/${postId}?mode=${mode}`
  },

  canvasImpression({
    impressionId,
    mode,
    language,
  }: {
    impressionId: string
    mode: string
    language: string
  }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/impressions/${impressionId}?mode=${mode}&lang=${language}`
  },

  canvasUser({ username, mode }: { username: string; mode: string }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/users/${username}?mode=${mode}`
  },

  canvasSeries({ seriesId, mode }: { seriesId: string; mode: string }): string {
    // the mode parameter is the name of the social network for sharing
    return `${canvas}/series/${seriesId}?mode=${mode}`
  },

  goodNews(): string {
    return `/specials/good_news`
  },

  manageSubscriptionsInItunes(): string {
    return 'itms-apps://apps.apple.com/account/subscriptions'
  },

  addEmailVerificationSend(username: string, query?: QueryParams): string {
    return addParamsToPath(
      `/@${username}/settings/ru/add-email/success`,
      query || {},
    )
  },

  unavailableByRegion(): string {
    return '/restricted-by-region'
  },
}

export function removeTrailingSlash(url: string): string {
  return url.replace(/\/+$/, '')
}

// returns complete query string with a `?` prepended to its
export function getQueryString(params: NullableQueryParams): string {
  const existingParams = pickBy(params, value => Boolean(value))
  const queryString = prepareQueryString(existingParams as QueryParams)
  return queryString ? `?${queryString}` : ''
}

export function prepareQueryString(params: QueryParams): string {
  if (!Object.keys(params).length) return ''

  return map(params, (value, key) => {
    if (Array.isArray(value)) {
      return prepareArrayParams(key, value)
    } else if (value && typeof value === 'object') {
      return prepareAssociativeArrayParams(key, value)
    } else {
      return `${key}=${encodeURIComponent(value)}`
    }
  }).join('&')
}

export function addParamsToPath(
  path: string,
  params: NullableQueryParams,
): string {
  let queryString = getQueryString(params)
  if (queryString === '') {
    return path
  } else {
    queryString = path.includes('?') ? `&${queryString.slice(1)}` : queryString
    return `${path}${queryString}`
  }
}

function prepareArrayParams(key, values): string {
  // if the key already ends in square brackets (to indicate that is is a key of array params),
  // use it without modification; otherwise, add square brackets to its end
  const arrayMarkerRegex = new RegExp('\\[\\]$')
  // eslint-disable-next-line no-param-reassign
  key = arrayMarkerRegex.test(key) ? key : `${key}[]`

  return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
}

function prepareAssociativeArrayParams(key, value): string {
  // assume that the `value` arg is a shallow key-value object
  return Object.keys(value)
    .map(val => `${key}[${val}]=${value[val]}`)
    .join('&')
}

export function getSubdomainFromHostname(_hostname: string): string {
  const subdomain = _hostname?.split('.')[0]

  if (isValidLocale(subdomain) || subdomain === 'rus' || subdomain === GHANA) {
    return subdomain
  } else {
    return ''
  }
}

export function getSubdomainFromLocale(locale: string): string {
  if (locale === 'ru') return 'rus.'
  if (isValidLocale(locale)) {
    return `${locale}.`
  }

  return ''
}

export function getUploadedImagePath(filename: string): string {
  return `https://a.bmstatic.com/iu/${filename}`
}

export function createRedirectForResource(resourceType: string): TODO_ANY {
  const resourceRegex = (_resourceType =>
    new RegExp(`/${_resourceType}s/([^/]+)/?(.*)$`))(resourceType)

  return (uuid: string, currentPath: string): string => {
    return currentPath.replace(resourceRegex, `/${resourceType}s/${uuid}/$2`)
  }
}

export function getDomain(host: string): string {
  const parsedDomain = parseDomain(host)

  const appDomain =
    parsedDomain.type !== 'LISTED'
      ? host
      : [parsedDomain.domain, parsedDomain.topLevelDomains[0]].join('.')

  return appDomain
}

export function getUrlWithUtm(
  url: string,
  provider: string,
  login: string,
): string {
  const username = login ? `username=${login}&` : ''
  const utmSource = provider ? `utm_source=${provider}` : 'utm_source=na'
  const utmCampaign = 'utm_campaign=usersreferral'
  const utmContent = 'utm_content=web'
  const utmMedium = 'utm_medium=referral'

  const separator = url.includes('?') ? '&' : '?'

  return `${url}${separator}${username}${utmSource}&${utmCampaign}&${utmContent}&${utmMedium}`
}
